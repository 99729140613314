import React from "react";
const Text = ({
  size = "text-base",
  weight = "font-normal",
  text = "",
  className = "",
  onClick = () => {},
  whiteSpace = "whitespace-nowrap",
  overFlow = "overflow-hidden",
  ellipsis = "text-ellipsis",
  children,
}) => {
  return (
    <div
      className={`${ellipsis} ${overFlow} ${whiteSpace} ${size} ${weight} ${className}`}
      onClick={onClick}
    >
      {text !== "" && text !== undefined && text !== null ? text : children}
    </div>
  );
};
export default Text;
