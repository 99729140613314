import * as Yup from "yup";
import {
  GetUserWorkSpaces,
  AddWorkSpace,
  GetWorkSpace,
  UpdateWorkSpace,
  DeleteWorkspace,
  getUserSocialProfile,
  GmailAccesTokenService,
  CheckFbr,
  ResetWorkspace,
} from "../../services/workspaces";
import * as Constants from "./Constants";
import {
  WebstoreOptions,
  ShipperOptions,
  RoleOptions,
} from "../../config/config";
import { Routes } from "../../utils/Constants";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../config/AuthSetting";
import { error, getImageUrl } from "../shared/Helpers";
const USER_NAME_REGEX = /^[a-zA-Z0-9 _-]+$/i;
const SOCIAL_NAME_REGEX = /^[a-zA-Z0-9-. _]+$/i;
const DOMAIN_REGEX =
  /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/;
// eslint-disable-next-line
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const CONTACT_REGEX = /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})?$/;

export const workspaceInitialValues = {
  name: "",
  file: null,
  contact: "",
  address: "",
  email: "",
  businessCategory: "",
  numberOfProducts: 0,
  monthlyOrders: 0,
};
export const workspaceSchema = Yup.object().shape({
  name: Yup.string()
    .required("validation.workspace.name.required")
    .matches(USER_NAME_REGEX, "validation.name.regex"),
  file: Yup.mixed().nullable().required("validation.workspace.icon.required"),
  email: Yup.string()
    .required("validation.email.required")
    .email("validation.email.invalid"),
  contact: Yup.string()
    .required("validation.contact.required")
    .min(11)
    .max(15)
    .matches(CONTACT_REGEX, "validation.workspace.contact.invalid"),
  address: Yup.string()
    .required("validation.address.required")
    .max(100, "validation.name.max.100"),
  businessCategory: Yup.string()
    .required("validation.businessCategory.required")
    .max(100, "validation.name.max.100")
    .trim(),
  numberOfProducts: Yup.number().min(0, "validation.minimum.zero"),
  monthlyOrders: Yup.number().min(0, "validation.minimum.zero"),
});
const defaultMember = {
  index: Math.random().toString(36).substring(7),
  id: null,
  email: "",
  role: { id: "admin", name: "Admin" },
};
export const initialValues = {
  name: "",
  contact: "",
  address: "",
  businessCategory: "",
  numberOfProducts: 0,
  monthlyOrders: 0,
  email: "",
  websiteUrl: "",
  facebookUrl: "",
  instagramUrl: "",
  chatGptApiKey: "",
  twitterUrl: "",
  websiteId: "",
  websiteType: null,
  webUrl: "",
  webConsumerKey: "",
  webConsumerSecret: "",
  accessToken: "",
  shipperId: "",
  shipperProvider: null,
  shipperUserName: "",
  shipperPassword: "",
  googleClientId: "",
  googleClientSecret: "",
  shipperClientId: "",
  createdBy: null,
  file: null,
  // members: [
  //   defaultMember,
  //   // { ...defaultMember, index: Math.random().toString(36).substring(7) },
  //   // { ...defaultMember, index: Math.random().toString(36).substring(7) },
  // ],
  importRawData: false,
  fbrAccessToken: "",
  fbrKey: "",
};
export const schema = Yup.object().shape({
  name: Yup.string()
    .required("validation.workspace.name.required")
    .max(30, "validation.name.max")
    .matches(USER_NAME_REGEX, "validation.name.regex"),
  email: Yup.string().optional().email("validation.email.invalid"),
  chatGpt_api_key: Yup.string().optional(),
  contact: Yup.string()
    .optional()
    .min(11)
    .max(15)
    .matches(CONTACT_REGEX, "validation.workspace.contact.invalid"),
  address: Yup.string().optional().max(100, "validation.name.max.100"),
  websiteUrl: Yup.string().optional().matches(DOMAIN_REGEX, "validation.url"),
  businessCategory: Yup.string().max(100, "validation.name.max.100").trim(),
  numberOfProducts: Yup.number().min(0, "validation.minimum.zero"),
  monthlyOrders: Yup.number().min(0, "validation.minimum.zero"),
  facebookUrl: Yup.string()
    .optional()
    .matches(SOCIAL_NAME_REGEX, "validation.socialname.regex"),
  instagramUrl: Yup.string()
    .optional()
    .matches(SOCIAL_NAME_REGEX, "validation.socialname.regex"),
  twitterUrl: Yup.string()
    .optional()
    .matches(SOCIAL_NAME_REGEX, "validation.socialname.regex"),
  websiteType: Yup.object().nullable(),
  webUrl: Yup.string().when("websiteType", {
    is: (websiteType) =>
      websiteType && websiteType.id === Constants._WOOCOMERCE, //just an e.g. you can return a function
    then: Yup.string().url().required("validation.url.required"),
    otherwise: Yup.string(),
  }),
  webConsumerKey: Yup.string().when("websiteType", {
    is: (websiteType) =>
      websiteType && websiteType.id === Constants._WOOCOMERCE, //just an e.g. you can return a function
    then: Yup.string().required("validation.consumerkey.required"),
    otherwise: Yup.string().max(100, "validation.name.max.100"),
  }),
  webConsumerSecret: Yup.string().when("websiteType", {
    is: (websiteType) =>
      websiteType && websiteType.id === Constants._WOOCOMERCE, //just an e.g. you can return a function
    then: Yup.string().required("validation.consumersecret.required"),
    otherwise: Yup.string().max(100, "validation.name.max.100"),
  }),
  accessToken: Yup.string().when("websiteType", {
    is: (websiteType) => websiteType && websiteType.id === Constants._SHOPIFY, //just an e.g. you can return a function
    then: Yup.string().required("validation.accesstoken.required"),
    otherwise: Yup.string().max(100, "validation.name.max.100"),
  }),
  shipperProvider: Yup.object().nullable(),
  shipperUserName: Yup.string().when("shipperProvider", {
    is: (shipperProvider) => shipperProvider && shipperProvider.id, //just an e.g. you can return a function
    then: Yup.string().required("validation.shipper.username.required"),
    otherwise: Yup.string().max(100, "validation.name.max.100"),
  }),
  shipperPassword: Yup.string().when("shipperProvider", {
    is: (shipperProvider) => shipperProvider && shipperProvider.id, //just an e.g. you can return a function
    then: Yup.string().required("validation.shipper.passowrd.required"),
    otherwise: Yup.string().max(100, "validation.name.max.100"),
  }),
  shipperClientId: Yup.string().when("shipperProvider", {
    is: (shipperProvider) => shipperProvider && shipperProvider.id, //just an e.g. you can return a function
    then: Yup.string().required("validation.shipper.api.key.required"),
    otherwise: Yup.string().max(100, "validation.name.max.100"),
  }),
  file: Yup.mixed().nullable().required("validation.workspace.icon.required"),
  // members: Yup.array().of(
  //   Yup.object().shape({
  //     email: Yup.string()
  //       .required("validation.email.required")
  //       .email("validation.email.invalid"),
  //     role: Yup.object().nullable(),
  //   })
  // ),
  skuSeparator: Yup.string()
    .optional()
    .matches(
      /^[!@#$%^&*()_+[\]{}|;:'",.<>?\\/-]+$/,
      "validation.skuSeparator.specialCharacter"
    ),
});
export const defaultWorkspaces = {
  loading: true,
  data: [],
};
export const defaultWorkspace = {
  open: false,
  initial: false,
  socialProfile: false,
  loading: false,
  data: null,
};

export const isWorkspaceCreateAllowed = (user, workspaces) => {
  return (
    user?.type === Constants._AGENCY ||
    (user?.type === Constants._INDIVIDUAL && workspaces.data.length === 0)
  );
};

export const onAddNewMember = (members, setFieldValue) => {
  setFieldValue("members", [
    ...members,
    {
      ...defaultMember,
      index: Math.random().toString(36).substring(7),
    },
  ]);
};
export const getAllWorkspaces = async (setWorkspaces) => {
  const resp = await GetUserWorkSpaces();
  if (resp.status === 200 && resp.data && resp.data.length > 0) {
    setWorkspaces((prev) => {
      return { ...prev, data: resp.data, loading: false };
    });
  } else {
    setWorkspaces((prev) => {
      return { ...prev, data: [], loading: false };
    });
  }
};
export const getWorkspace = async (id, setWorkspace) => {
  const resp = await GetWorkSpace(id);
  if (resp.status === 200 && resp.data) {
    setWorkspace((prev) => {
      return { ...prev, data: resp.data };
    });
  } else {
    error(resp.message);
  }
};

export const generateWorkspaceUrls = (workspaceId) => {
  const workspaceRoutes = [
    "products",
    "dashboard",
    "orders",
    "shippers",
    "socialProfiles",
    "orders-dashboard",
    "members",
  ];
  const baseUrl = Routes.WORKSPACES + "/" + workspaceId;
  const urls = {};
  workspaceRoutes.forEach((endpointName) => {
    urls[endpointName] = `${baseUrl}/${endpointName}`;
  });

  return urls;
};

export const getSocialIcon = (socialName) => {
  const imagePaths = {
    facebook: "/images/facebook.png",
    instagram: "/images/instagram.png",
    linkedin: "/images/linkedin.png",
    tiktok: "/images/tiktok.png",
  };
  return imagePaths[socialName] || getImageUrl();
};

export const handleShippers = (shippers) => {
  return shippers.map((ship) => {
    const s = ShipperOptions.find(
      (s) => s.name.toLowerCase() === ship.toLowerCase()
    );
    return {
      name: ship,
      icon: s.icon,
    };
  });
};

export const addWorkspace = async (data, setLoading, handles, closeModal) => {
  setLoading((prev) => !prev);
  try {
    const response = await AddWorkSpace(data);
    handles.resetForm();
    closeModal(response.data);
  } catch (err) {
    error(err.message);
  }
  setLoading((prev) => !prev);
};

export const updateWorkspace = async (workspaceId, data, setLoading) => {
  setLoading((prev) => !prev);
  const updatedWorkspace = { ...data, workspaceId };
  const resp = await UpdateWorkSpace(updatedWorkspace);
  if (resp.code === 200 && resp.data) {
    setLocalStorageItem("workspace", resp.data);
  } else {
    error(resp.data);
  }
  setLoading((prev) => !prev);
};

export const deleteWorkspace = async (data, setWorkspace, setWorkspaces) => {
  setWorkspace((prev) => ({ ...prev, loading: true }));
  const resp = await DeleteWorkspace(data._id);
  if (resp.code === 200) {
    setWorkspaces((prev) => ({
      ...prev,
      data: prev.data.filter((d) => d._id !== data._id),
    }));
  } else {
    error(resp.message);
  }
  setWorkspace((prev) => ({ ...prev, loading: false }));
};

export const resetWorkspace = async (data, setWorkspace) => {
  setWorkspace((prev) => {
    return { ...prev, loading: true };
  });
  let user = getLocalStorageItem("user");
  let body = { workspaceId: data.id, userId: user && user.id };
  const resp = await ResetWorkspace(body);
  if (resp.status === 200) {
    setWorkspace((prev) => {
      return { ...prev, loading: false };
    });
  } else {
    error(resp.message);
    setWorkspace((prev) => {
      return { ...prev, loading: false };
    });
  }
};

export const setWorkspaceValues = (workspace, setFieldValue) => {
  if (workspace) {
    setFieldValue(Constants._NAME, workspace.name || "");
    setFieldValue(Constants._CHATGPT_API_KEY, workspace.chatGptApiKey || "");
    setFieldValue(
      Constants._WORKSPACE_IMAGE,
      getImageUrl(workspace, "iconUrl")
    );
    setFieldValue(Constants._CONTACT, workspace.contact || "");
    setFieldValue(Constants._BUSINESS_CATEGORY, workspace.businessCategory || "");
    setFieldValue(Constants._NUMBER_OF_PRODUCTS, workspace.numberOfProducts || "");
    setFieldValue(Constants._MONTHLY_ORDER, workspace.monthlyOrders || "");
    setFieldValue(Constants._EMAIL, workspace.email || "");
    setFieldValue(Constants._ADDRESS, workspace.address || "");
    setFieldValue(Constants._WEBSITE_URL, workspace.websiteUrl || "");
    setFieldValue(Constants._FACEBOOK_URL, workspace.facebookUrl || "");
    setFieldValue(Constants._INSTAGRAM_URL, workspace.instagramUrl || "");
    setFieldValue(Constants._TWITTER_URL, workspace.twitterUrl || "");
    setFieldValue(Constants._SKU_SEPARATOR, workspace.skuSeparator || "");
    setFieldValue("createdBy", workspace.createdBy || "");
  }
};
export const isMemberDisabled = (values, userDetails, index) => {
  if (userDetails.isMember) {
    return true;
  } else {
    return (
      userDetails.email ===
        values[Constants._MEMBERS][index][Constants._EMAIL] ||
      (values.createdBy
        ? values[Constants._MEMBERS][index]["_id"] === values.createdBy
        : false)
    );
  }
};

export const setWebstoreValues = (website, setFieldValue) => {
  if (website) {
    const web = WebstoreOptions.find(
      (s) => s.id.toLowerCase() === website.website_type.toLowerCase()
    );
    setFieldValue(Constants._WEBSITE_ID, website.id || "");
    setFieldValue(Constants._WEBSITE_TYPE, web || null);
    setFieldValue(Constants._WEB_URL, website.url || "");
    setFieldValue(Constants._WEB_CONSUMER_KEY, website.consumer_key || "");
    setFieldValue(
      Constants._WEB_CONSUMER_SECRET,
      website.consumer_secret || ""
    );
    setFieldValue(Constants._ACCESS_TOKEN, website.access_token || "");
  }
};
export const clearWebstoreFields = (setFieldValue) => {
  setFieldValue(Constants._WEB_URL, "");
  setFieldValue(Constants._WEB_CONSUMER_KEY, "");
  setFieldValue(Constants._WEB_CONSUMER_SECRET, "");
  setFieldValue(Constants._ACCESS_TOKEN, "");
};
export const setCourierValues = (shipper, setFieldValue) => {
  if (shipper) {
    const ship = ShipperOptions.find(
      (s) => s.name.toLowerCase() === shipper.provider.toLowerCase()
    );
    setFieldValue(Constants._SHIPPER_ID, shipper.id || "");
    setFieldValue(Constants._SHIPPER_PROVIDER, ship || null);
    setFieldValue(Constants._SHIPPER_USERNAME, shipper.user_name || "");
    setFieldValue(Constants._SHIPPER_PASSWORD, shipper.password || "");
    setFieldValue(Constants._SHIPPER_CLIENT_ID, shipper.client_id || "");
  }
};
export const clearCourierFields = (setFieldValue) => {
  setFieldValue(Constants._SHIPPER_USERNAME, "");
  setFieldValue(Constants._SHIPPER_PASSWORD, "");
  setFieldValue(Constants._SHIPPER_CLIENT_ID, "");
};
export const setMemberValues = (members, setFieldValue) => {
  if (members && members.length > 0) {
    const data = members.map((member) => {
      const role = RoleOptions.find((r) => r.id === member.role);
      return {
        _id: member.id || null,
        email: member.email || "",
        name: member.name || "",
        isMember: false,
        role: role || { id: "admin", name: "Admin" },
        index: Math.random().toString(36).substring(7),
      };
    });
    if (data.length === 0) {
      data.push({
        ...defaultMember,
        index: Math.random().toString(36).substring(7),
      });
    }
    setFieldValue(Constants._MEMBERS, data);
  }
};
export const onPasteData = (e, setFieldValue) => {
  if (e.clipboardData.getData("Text")) {
    setFieldValue(Constants._ALL_DATA, e.clipboardData.getData("Text"));
    const data = e.clipboardData.getData("Text").split("~");
    setFieldValue(Constants._EMAIL, data[0]);
    setFieldValue(
      Constants._CONTACT,
      data[1].replace(/\s/g, "").replace(/\+/g, "")
    );
    setFieldValue(Constants._ADDRESS, data[2]);
    if (data[3].toLowerCase() === Constants._SHOPIFY) {
      setFieldValue(
        Constants._WEBSITE_TYPE,
        WebstoreOptions.find((w) => w.id === Constants._SHOPIFY)
      );
      setFieldValue(Constants._WEB_URL, data[4]);
      setFieldValue(Constants._ACCESS_TOKEN, data[5]);
      setFieldValue(Constants._WEBSITE_URL, data[6]);
      setFieldValue(Constants._INSTAGRAM_URL, data[7]);
      setFieldValue(Constants._FACEBOOK_URL, data[8]);
      setFieldValue(Constants._TWITTER_URL, data[9]);
    }
    let arr = [];
    if (data[10]) {
      arr = JSON.parse(data[10]);
      if (arr.length > 0) {
        arr = arr.map((a) => {
          return {
            index: Math.random().toString(36).substring(7),
            id: null,
            email: a.email,
            role: RoleOptions.find(
              (r) => r.name.toLowerCase() === a.role.toLowerCase()
            ),
          };
        });
      }
    }
    let shipper = "";
    let otherData = "";
    if (data[11]) {
      shipper = JSON.parse(data[11]);
      otherData = shipper;
      shipper = ShipperOptions.find(
        (s) => s.name.toLowerCase() === shipper.provider.toLowerCase()
      );
    }
    setFieldValue(Constants._MEMBERS, arr);
    setFieldValue(Constants._SHIPPER_PROVIDER, shipper);
    setFieldValue(Constants._SHIPPER_USERNAME, otherData.shipperusername);
    setFieldValue(Constants._SHIPPER_PASSWORD, otherData.shipperpassword);
    setFieldValue(Constants._SHIPPER_CLIENT_ID, otherData.clientid);
  }
};
export const defaultSocialProfile = {
  data: [],
  open: false,
  list: true,
  submitLoading: false,
  loading: false,
  socialProfileModal: false,
  workspaceId: null,
};
export const GetSocialProfile = async (setProfile) => {
  setProfile((prev) => ({ ...prev, loading: true }));
  const resp = await getUserSocialProfile();
  if (resp.status === 200) {
    setProfile((prev) => {
      return {
        ...prev,
        loading: false,
        data:
          resp && resp.data && resp.data.length > 0
            ? resp.data.map((r) => {
                return { ...r, checked: false };
              })
            : [],
      };
    });
  } else {
    setProfile((prev) => ({ ...prev, loading: false }));
  }
};
export const checkboxHandle = (checked, id, setSocialProfile) => {
  if (checked) {
    setSocialProfile((prev) => {
      return {
        ...prev,
        data:
          prev && prev.data.length > 0
            ? prev.data.map((d) => (d.id === id ? { ...d, checked: true } : d))
            : [],
      };
    });
  } else {
    setSocialProfile((prev) => {
      return {
        ...prev,
        data:
          prev && prev.data.length > 0
            ? prev.data.map((d) => (d.id === id ? { ...d, checked: false } : d))
            : [],
      };
    });
  }
};
export const gmailAccessToken = async (values) => {
  const resp = await GmailAccesTokenService(
    values.googleClientId,
    values.googleClientSecret
  );
  if (resp.status === 200) {
    resp && resp.data && resp.data.url && window.open(resp.data.url);
  }
};

export const modules = [
  "businessSettings",
  "conversations",
  "dashboard",
  "descriptions",
  "designJobs",
  "designs",
  "integrations",
  "labels",
  "orderActivity",
  "orders",
  "members",
  "permissions",
  "products",
  "reports",
  "settings",
  "shipperSettings",
  "signatures",
  "socialProfiles",
  "taskManager",
  "workspace",
];

export const workspaceModule = ["workspace"];

export const permissionsWithModule = (modules, permissions) => {
  return modules.map((module) => {
    return {
      module: module,
      permissions:
        permissions &&
        permissions.filter((permission) => permission.module === module),
    };
  });
};

export const getUniquePermissions = (users) => {
  const uniquePermissions = Object.values(
    users.reduce((acc, { permissions }) => {
      permissions &&
        permissions.forEach((permission) => {
          const { module, action } = permission;
          if (module === "workspace") {
            const key = `${module}-${action}`;

            if (!acc[key]) {
              acc[key] = {
                module,
                action,
                value: permission.value,
                type: null,
              };
            } else if (permission.value === "yes") {
              acc[key].value = "yes";
            }
          }
        });

      return acc;
    }, {})
  );

  return {
    module: "workspace",
    permissions: uniquePermissions,
  };
};

export const checkFbrIntegration = async (id, setFbrCheck) => {
  const check = await CheckFbr(id);
  if (check.flag) {
    setFbrCheck(true);
  }
};
