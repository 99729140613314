import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { WorkspaceReducer } from "./Workspace";
import { PostReducer } from "./Post";
import UserReducer from "./User/reducer";
import { PermissionsReducer } from "./Permissions";
import { SocialProfileReducer } from "./SocialProfile";
import { CartReducer } from "./Ecommerce";
import { ConversationOrderReducer } from "./ConversationOrder";
import { AddCardModalReducer } from "./TaskManager";
import { ConversationTabsReducer } from "./ConversationTabs";
import { ConversationActiveContactReducer } from "./ConversationActiveContact";
import { ConversationMediaReducer } from "./ConversationMediaAttachments";
import { ConversationLabelReducer } from "./ConversationLabels";
import { SocketReducer } from "./Socket";
import { EmailTemplateReducer } from "./ConversationEmailTemplate";
import { ActiveStateReducer } from "./Order";
import { EmailReplyReducer } from "./ConversationEmailReply";
// import logger from "redux-logger";
// import promise from "redux-promise-middleware";
const appReducer = combineReducers({
	/* your app’s top-level reducers */
	workspace: WorkspaceReducer,
	user: UserReducer,
	permissions: PermissionsReducer,
	socialProfileModal: SocialProfileReducer,
	cart: CartReducer,
	post: PostReducer,
	conversationOrder: ConversationOrderReducer,
	addCardModal: AddCardModalReducer,
	conversationTab: ConversationTabsReducer,
	conversationActiveContact: ConversationActiveContactReducer,
	conversationMediaAttachmnet: ConversationMediaReducer,
	conversationLabels: ConversationLabelReducer,
	socket: SocketReducer,
	conversationEmailTemplate: EmailTemplateReducer,
	order: ActiveStateReducer,
	reply: EmailReplyReducer,
});
const middlewares = [thunk];
export default createStore(appReducer, {}, applyMiddleware(...middlewares));
