import {
  SetLabelArr,
  SetLoading,
  BackDropLoading,
  SetLabelRespData,
} from "./action";
import ConversationLabelReducer from "./reducer";
import {
  GetLabels,
  DeleteLabel,
  UnassignLabel,
  AssignLabel,
} from "../../services/workspace/labels";
import { error } from "../../components/shared/Helpers";

const getLabels = () => {
  return (dispatch) => {
    dispatch(SetLoading(true));
    GetLabels().then((res) => {
      dispatch(SetLoading(false));
      if (res.status === 200) {
        dispatch(SetLabelArr(res.data));
      }
    });
  };
};

const deleteLabelHandler = (id, labelsList) => {
  return (dispatch) => {
    dispatch(BackDropLoading(true));

    DeleteLabel(id).then((resp) => {
      dispatch(BackDropLoading(false));
      if (resp && resp.status === 200) {
        const labelsData =
          labelsList && labelsList.length > 0
            ? labelsList.filter((item) => item._id !== id)
            : [];
        dispatch(SetLabelArr(labelsData));
      } else {
        error(resp.message);
      }
    });
  };
};

const unAssignLabelsHandler = (id, bodyData) => {
  return (dispatch) => {
    dispatch(BackDropLoading(true));
    UnassignLabel(id, bodyData).then((resp) => {
      if (resp && resp.data) {
        dispatch(getLabels());
        dispatch(
          SetLabelRespData({
            ...resp.data,
            contactId: bodyData.contactId,
            assign: false,
          })
        );
      }
      dispatch(BackDropLoading(false));
    });
  };
};

const AssignLabelsHandler = (id, bodyData) => {
  return (dispatch) => {
    dispatch(BackDropLoading(true));
    AssignLabel(id, bodyData).then((resp) => {
      if (resp && resp.data) {
        dispatch(getLabels());
        dispatch(
          SetLabelRespData({
            ...resp.data,
            contactId: bodyData.contactId,
            assign: true,
          })
        );
      }
      dispatch(BackDropLoading(false));
    });
  };
};

export {
  ConversationLabelReducer,
  SetLabelArr,
  getLabels,
  deleteLabelHandler,
  unAssignLabelsHandler,
  AssignLabelsHandler,
};
