import * as Type from "./types";
const ConversationActiveContactReducer = (state = {}, action) => {
  switch (action.type) {
    case Type.SET_ACTIVE_CONTACT:
      state = {
        ...state,
        activeContact: action.payload,
      };
      break;
    case Type.SET_HANDLE_REFRESH:
      state = {
        ...state,
        refreshContactList: action.payload,
      };
      break;
    default:
      break;
  }
  return state;
};

export default ConversationActiveContactReducer;
