export const _NAME = "name";
export const _BUSINESS_CATEGORY = "businessCategory";
export const _NUMBER_OF_PRODUCTS = "numberOfProducts";
export const _MONTHLY_ORDER = "monthlyOrders";
export const _CONTACT = "contact";
export const _ADDRESS = "address";
export const _EMAIL = "email";
export const _INDIVIDUAL = "individual";
export const _AGENCY = "agency";
export const _DASHBOARD = "dashboard";
export const _READDASHBOARD = "readDashboard";
export const _CHATGPT_API_KEY = "chatGptApiKey";
export const _SKU_SEPARATOR = "skuSeparator";
export const _WEBSITE_URL = "websiteUrl";
export const _FACEBOOK_URL = "facebookUrl";
export const _INSTAGRAM_URL = "instagramUrl";
export const _TWITTER_URL = "twitterUrl";
export const _IS_MEMBER = "isMember";
export const _WEBSITE_ID = "websiteId";
export const _WEBSITE_TYPE = "websiteType";
export const _WEB_URL = "webUrl";
export const _WEB_CONSUMER_KEY = "webConsumerKey";
export const _WEB_CONSUMER_SECRET = "webConsumerSecret";
export const _ACCESS_TOKEN = "accessToken";
export const _FBR_ACCESS_TOKEN = "fbrAccessToken";
export const _SHIPPER_ID = "shipperId";
export const _SHIPPER_PROVIDER = "shipperProvider";
export const _SHIPPER_USERNAME = "shipperUserName";
export const _SHIPPER_PASSWORD = "shipperPassword";
export const _SHIPPER_CLIENT_ID = "shipperClientId";
export const _GOOGLE_CLIENT_ID = "googleClientId";
export const _GOOGLE_CLIENT_SECRET = "googleClientSecret";
export const _WORKSPACE_IMAGE = "file";
export const _ALL_DATA = "allData";
export const _MEMBERS = "members";
export const _NEWROLE = "newRole";
export const _PREVROLE = "prevRole";
export const _ROLE = "role";
export const _SHOPIFY = "shopify";
export const _WOOCOMERCE = "woocommerce";
export const _Workspace = "workspace";
export const createWorkspace = "createWorkspace";
export const readWorkspace = "readWorkspace";
export const updateWorkspace = "updateWorkspace";
export const _deleteWorkspace = "deleteWorkspace";
export const addMembers = "addMembers";
export const addSocialPages = "addSocialProfiles";
export const addWebstoreCredentials = "addWebstoreCredentials";
export const addShipper = "addShipper";
export const Id = "id";
export const _Id = "_id";
export const _FBR_KEY = "fbrKey";
export const _NTN = "ntn";
export const SHUTTLE_PRO = "shuttlePro";
export const READ_SHIPPERS = "readShippers";
