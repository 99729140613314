import { toast } from "react-toastify";
import { FetchUserPermissions } from "../../../services/workspace/permissions";
import { modules } from "../../workspaces/helper";
import { getLocalStorageItem } from "../../../config/AuthSetting";
import { UpdateUserToken } from "../../../services/workspaces";
export const success = (value, option = {}) => toast.success(value, option);
export const error = (value, option = {}) => toast.error(value, option);

const defaultImage =
  "https://s3-shuttlepro-bucket.s3.amazonaws.com/workspaces//icons/82/thumb_1670217441420-images_2_.png";

export const getImageUrl = (workspace, icon = "icon") => {
  if (workspace && workspace[icon]) {
    return workspace[icon];
  }
  if (
    workspace &&
    workspace[icon] &&
    workspace[icon].thumb &&
    workspace[icon].thumb.url
  ) {
    return workspace[icon].thumb.url;
  } else {
    return defaultImage;
  }
};
// export const getImageUrl = (workspace, icon = "icon") => {
//
//   if (workspace.thumbUrl || workspace.iconUrl || workspace.profilePicture) {
//     return workspace.thumbUrl || workspace.iconUrl || workspace.profilePicture;
//   }
//   const iconUrl = workspace[icon]?.thumb?.url;
//
//   return iconUrl || defaultImage;
// };

export const checkPermission = (permissions, module, action, type = null) => {
  const user = getLocalStorageItem("user");
  const superAdmin = user?.role === "super";
  if (superAdmin) return true;
  if (!permissions || (permissions && permissions.length === 0)) {
    // no permissions available, deny access
    return true;
  } else {
    const permission =
      permissions &&
      permissions.length > 0 &&
      permissions.find(
        (p) =>
          p.module === module &&
          p.action === action &&
          p.type === (type === null || type === "" ? "" : type)
      );
    return permission && permission.value === "yes";
  }
};

export const permissionsWithModule = (modules, permissions) => {
  return modules.map((module) => {
    return {
      module: module,
      permissions:
        permissions &&
        permissions.name !== "error" &&
        permissions.length > 0 &&
        permissions.filter((permission) => permission.module === module),
    };
  });
};

export const permissionsWithModuleFilter = (module, permissions) => {
  return {
    module: module,
    permissions:
      permissions &&
      permissions.length > 0 &&
      permissions.filter((permission) => permission.module === module),
  };
};

// export const checkPermissionWithModule = (
//   permissions,
//   module,
//   action,
//   type = null
// ) => {
//   if (permissions === undefined || permissions === null) return true;
//   const permissionBymodule =
//     permissions &&
//     permissions.length > 0 &&
//     permissions.filter((permission) => permission.module === module);
//   if (
//     (permissionBymodule && permissionBymodule.length === 0) ||
//     (permissionBymodule &&
//       permissionBymodule[0].permissions &&
//       permissionBymodule[0].permissions.length === 0) ||
//     (permissionBymodule && permissionBymodule === null)
//   ) {
//     // no permissions available, deny access
//     return true;
//   } else {
//     const permission =
//       permissionBymodule &&
//       permissionBymodule[0].permissions.find(
//         (p) => p.module === module && p.action === action && p.type === type
//       );
//     return permission && permission.value === "yes";
//   }
// };

/**
 * Checks whether a user has permission to perform an action in a module.
 * @param {Array} permissions - The user's permissions.
 * @param {string} module - The module in which the action is being performed.
 * @param {string} action - The action being performed.
 * @param {string|null} type - The type of action being performed, if applicable.
 * @returns {boolean} - Whether the user has permission to perform the action.
 */
export const checkPermissionWithModule = (
  permissions,
  module,
  action,
  type = ""
) => {
  const user = getLocalStorageItem("user");
  const superAdmin = user?.role === "super";
  if (superAdmin) return true;
  if (permissions === undefined || permissions === null) {
    // If the user has no permissions, allow access
    return false;
  }
  const permissionByModule = permissions.find((p) => p.module === module);
  if (
    !permissionByModule ||
    !permissionByModule.permissions ||
    permissionByModule.length === 0
  ) {
    // If the module is not found or has no permissions, deny access
    return false;
  }

  const permission = permissionByModule.permissions.find(
    (p) => p.action === action && p.type === type
  );
  // const permission = permissionByModule.permissions.find((p) => {
  //   console.log("p", p.action, action, p.type, type);
  //   if (p.action === action && (p.type === type || type === "")) return p;
  // });

  if (!permission) {
    // If the action is not found, deny access
    return false;
  }

  return permission.value === "yes";
};

export const filterRoutesByComponent = (routes, componentName, routeName) => {
  // Find the route object with the path "Routes.PRODUCTS"
  const aa = routes.map((route) =>
    route.name === componentName
      ? {
          ...route,
          routes: route.routes.filter((route) => route.name !== routeName),
        }
      : route
  );
  return aa;
};

export const filterRoutes = (routes, componentName) => {
  // Find the route object with the path "Routes.PRODUCTS"
  const customRoute = routes.filter((route) => route.name !== componentName);
  // Return the updated routes array
  return customRoute;
};

export const getAllUserPermissions = async (id) => {
  const resp = await FetchUserPermissions(id);
  if (resp && resp.status === 200 && resp.data && resp.data.length > 0) {
    const permissions = permissionsWithModule(modules, resp.data);
    return permissions;
  }
};

export const updateUserToken = async (workspace) => {
  const resp = await UpdateUserToken(workspace);
  if (resp.status === 200 && resp.data) {
    return resp;
  }
};
