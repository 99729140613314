import * as Type from "./types";
const ConversationLabelReducer = (
  state = {
    loading: true,
    labels: [],
    labelIds: [],
    backdropLoading: false,
  },
  action
) => {
  switch (action.type) {
    case Type.SET_LABEL_ARR:
      state = {
        ...state,
        labels: action.payload,
        labelIds:
          (action.payload && action.payload.map((label) => label._id)) || [],
      };
      break;
    case Type.SET_LABEL_RESP_DATA:
      state = {
        ...state,
        respData: action.payload,
      };
      break;
    case Type.SET_LOADING:
      state = {
        ...state,
        loading: action.payload,
      };
      break;

    case Type.BACK_DROP_LOADING:
      state = {
        ...state,
        backdropLoading: action.payload,
      };
      break;

    default:
      break;
  }
  return state;
};

export default ConversationLabelReducer;
